.myBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .selectBoxTotal {
    width: 90%;
    padding: 20px;
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 20px;
    overflow: scroll;

    -ms-overflow-style: none;

    .selectBox {
      max-height: 400px;
    }

    .buttonBox {
      display: flex;
      justify-content: center;
    }
  }

  .selectBoxTotal::-webkit-scrollbar {
    display: none;
  }

  .myBlockForm {
    width: 90%;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 20px;

    .formBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;

      .formBoxSelect {
        width: 100%;
        padding: 1%;
      }

      .formBoxInput {
        width: 97.5%;
        padding: 1%;
      }
    }

    .submitButton {
      margin-top: 20px;
      padding: 10px 0;
      width: 100%;
    }
  }
}
