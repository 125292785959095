.mainPageTotal {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  body {
    height: 100%;
  }

  .mainPageContentsBox {
    display: flex;
    padding: 0 80px;
    justify-content: space-between;
    background-color: rgb(245, 245, 245);
    min-height: 1000px;

    .mainPageLeftBox {
      width: 30%;
    }

    .mainPageRightBox {
      width: 70%;
    }
  }
}
@media (max-width: 991px) {
  .mainPageTotal {
    .navBarTotal {
      padding: 0 20px;
    }

    .mainPageContentsBox {
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);

      .mainPageLeftBox {
        display: none;
      }

      .mainPageRightBox {
        width: 100%;
      }
    }
  }
}

@media (max-width: 420px) {
  .mainPageTotal {
    .navBarTotal {
      padding: 0 10px;
    }

    .mainPageContentsBox {
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      background-color: rgb(245, 245, 245);

      .mainPageLeftBox {
        display: none;
      }

      .mainPageRightBox {
        width: 100%;
      }
    }
  }
}

.deleteModal {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  text-align: center;

  z-index: 1002;

  .deleteModalContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 100px;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;

    .deleteModalContentTitle {
      font-weight: bold;
    }

    .deleteModalContentButtonBox {
      text-align: right;
      .deleteModalContentButtonCancle {
        margin: 10px 10px 0 10px;
        padding: 5px 10px;
      }
      .deleteModalContentButtonDelete {
        margin: 10px 10px 0 10px;
        padding: 5px 10px;
      }
    }
  }
}
