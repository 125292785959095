.mainPageRightContents {
  display: flex;
  margin: 10px 10px 0 10px;
  flex-direction: column;
  align-items: center;

  .mainPageClientMain {
    width: 100%;
  }

  .mainPageRightContentsSelect {
    width: 80px;
    background-color: white;
    border: none;
    border-radius: 10px;
    height: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
}
