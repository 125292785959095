.navBarTotal {
  width: 100%;
  padding: 0 85px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid rgb(245, 245, 245);
  position: relative;

  .navBarTotalLeft {
    display: flex;
    align-items: center;
    width: 50%;

    .navBarLogo {
      margin: 10px;
      width: 40px;
      height: 40px;
    }

    .navBarInput {
      margin: 5px;
      padding: 5px;
      width: 100%;
      height: 40px;
      border: 1px solid gray;
      border-radius: 5px;
    }
  }

  .navBarTotalRight {
    display: none;

    .navBarTotalRightImg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .navBarTotalRightContents {
    position: absolute;
    width: 70%;
    top: 62px;
    right: 0px;
    animation: fadeIn 0.5s ease-out;
  }

  .navBarTotalRightContents.close {
    animation: fadeOut 0.5s ease-out;
  }

  @media (max-width: 991px) {
    .navBarTotalLeft {
      width: 70%;
    }

    .navBarTotalRight {
      display: block;
      display: flex;
      align-items: center;
    }

    .navBarTotalRightContents {
      width: 50%;
      z-index: 100;
    }
  }

  @media (max-width: 420px) {
    .navBarTotalRightContents {
      width: 70%;
    }
  }
}

@keyframes fadeIn {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
