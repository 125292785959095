.makeManualCate {
  background-color: #fafafa;

  .newCategoryTotal {
    display: flex;
    justify-content: space-between;

    .newCategoryBox {
      padding: 20px;
      width: 60%;
      margin: 10px;
      border: 1px solid lightgray;
      border-radius: 20px;
      display: flex;
      flex-direction: column;

      .projectInfoBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .projectInformTitle {
          text-align: center;
          font-weight: bold;
          font-size: 1.17rem;
        }

        .projectInformBox {
          margin-top: 10px;

          .createInput {
            width: 96%;
            padding: 1%;
          }
        }

        .projectInformBox.BottonBox {
          display: flex;
          justify-content: space-between;
          width: 100%;

          label {
            width: 48%;
            span {
              width: 100%;
            }
          }
        }
      }
      .categoryInputBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .categoryInput {
          text-align: center;
          font-weight: bold;
          font-size: 1.17rem;
        }

        .detailCategoryCardBox {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }

    .createMainPageLeft {
      margin: 10px;
      border: 1px solid lightgray;
      border-radius: 20px;
      width: 40%;
    }
  }

  .selectBox {
    padding: 20px;
    margin: 10px;
    border: 1px solid lightgray;
    border-radius: 20px;

    // width: calc(100% - 40px);

    .selectBoxTitle {
      margin-top: 0;
      // width: calc(100% - 40px);
    }

    .buttonBox {
      padding-top: 5px;
      overflow: scroll;
      display: flex;
      flex-direction: column;

      .selectButton {
        padding: 10px 0;
        margin: 5px 0;
      }
    }
    .buttonBox::-webkit-scrollbar {
      display: none;
    }

    // .buttonBox {
    //   display: flex;
    //   justify-content: center;
    // }
  }

  .createButton {
    margin: 5px 0;
    width: 100%;
  }
}
