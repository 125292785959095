.mainPageLeftContents {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  position: relative;

  .mainPageLeftContentsBox {
    width: 95%;
    margin: 0 10px;

    .mainPageClientLogo {
      width: 100%;
    }

    .mainPageCategory {
      list-style: none;
      cursor: pointer;
      background-color: white;
      border-radius: 10px;

      max-height: 600px;
      overflow: scroll;

      -ms-overflow-style: none;
    }

    .mainPageCategory::-webkit-scrollbar {
      display: none;
    }
  }

  .mainPageLeftContentsControlBox {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
      background-color: white;
      border: none;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
