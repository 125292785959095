.mainPageContentCardBox {
  margin: 10px auto;
  padding: 0 10px;
  width: 100%;
  border-radius: 10px;
  background-color: white;

  .mainPageContentCardTop {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 10px 0;

    .printButton {
      position: absolute;
      top: 10px;
      right: 0px;
      width: 30px;
      height: 30px;
      animation: fadeIn 1s ease-out;
      cursor: pointer;
    }

    .printButton.close {
      animation: fadeOut 0.15s ease-out;
    }
  }

  .mainPageContentCardMid {
    padding: 10px 0;

    .mainPageContentCardMidTitle {
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.5s;


    }

    .mainPageContentCardMidTitle:hover {
      color: #1D67B1;
    }

    .mainPageContentCardMidDescription {
      padding: 0 10px;
      margin-top: 10px;
      animation: fadeIn 1s ease-out;

      img {
        cursor: pointer;
        width: 100% !important;
        height: 100% !important;
        border: 1px solid lightgray;
        border-radius: 10px;
      }
    }

    .mainPageContentCardMidDescription.close {
      animation: fadeOut 0.15s ease-out;
    }
  }

  .mainPageContentCardBottom {
    padding: 10px 0;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s; 
  }
  .mainPageContentCardBottom:hover {
    color: #1D67B1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
