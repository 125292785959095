.mainPageCategoryDetailListsBox {
  .mainPageCategoryDetailListTitle {
    padding: 15px 0px 15px 25px;
    border-bottom: 1px solid rgb(245, 245, 245);
    transition: all 0.5s;
    cursor: pointer;
  }

  .mainPageCategoryDetailListTitle.on{
    background-color: lightblue;
    font-weight: bold;
    transition: all 0.5s;
  }

  .mainPageCategoryDetailListsDetail {
    padding: 15px 0px 15px 35px;
    border-bottom: 1px solid rgb(245, 245, 245);
    animation: fadeIn 0.5s ease-out;
    transition: all 0.5s;
    cursor: pointer;
  }

  .mainPageCategoryDetailListsDetail.close {
    animation: fadeOut 0.15s ease-out;
  }

  .mainPageCategoryDetailListsDetail.on {
    background-color: lightblue;
    font-weight: bold;
    transition: all 0.5s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
