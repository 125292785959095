.detailCategoryCardBox {
  display: flex;
  flex-direction: column;
  width: 100%;

  .detailCategoryCardBoxInput {
    width: 96%;
    padding: 1.5%;
  }

  .detailCategoryCardBoxButton {
    margin: 5px 0;
    padding: 10px 0;
    width: 50%;
  }

  .subMenuBox {
    display: flex;
    width: 100%;

    .blogTagBox {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}
