.finalCategory {
  .finalCategoryInput {
    width: 96%;
    padding: 1.5%;
  }

  .blogTagContents {
    padding: 10px;
    margin: 10px;
    background-color: rgb(245, 245, 245);

    cursor: pointer;
  }
}
