.mainPageCategoryListsBox {
  border-bottom: 1px solid rgb(245, 245, 245);

  .mainPageCategoryListTitle {
    width: 100%;
    padding: 15px 15px;
    font-weight: bold;
    border-bottom: 1px solid rgb(245, 245, 245);
  }

  .mainPageCategoryDetail {
    margin-bottom: 0;
    list-style: none;
    animation: fadeIn 0.5s ease-out;
  }
  .mainPageCategoryDetail.close {
    animation: fadeOut 0.15s ease-out;
  }
}

@keyframes fadeIn {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
